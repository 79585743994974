<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >

          <b-table
              ref="table"
              :current-page="currentPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="getConnectedLocation"
              :per-page="pagination.perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              class="mobile_table_css"
              hover
              responsive
              show-empty
          >
            <template #cell(duration)="data">
              <!--              <div v-if="data.item.is_day">-->
              <!--                <span v-if="data.item.duration > 1">{{data.item.duration}} days</span>-->
              <!--                <span v-else >{{data.item.duration}} day</span>-->
              <!--              </div>-->
              <!--              <div v-else>-->
              <!--                <span v-if="data.item.duration > 1" >{{data.item.duration}} hours</span>-->
              <!--                <span v-else >{{data.item.duration}} hour</span>-->

              <!--              </div>-->


            </template>

            <template #cell(status)="data">
              <b-badge v-if="data.item.status === 'Paid'" class="width-100" style="padding:8px" variant="light-primary">
                <span class=""> {{ data.item.status }} </span>
              </b-badge>
              <b-badge v-else class="width-100" style="padding:8px" variant="light-warning">
                <span class=""> {{ data.item.status }} </span>
              </b-badge>
              <!--              <b-badge v-if="data.item.status ==='Approved'" class="" style="padding:8px" variant="success" >-->
              <!--                <span class=""> {{data.item.status}} </span>-->
              <!--              </b-badge>-->
              <!--              <b-badge v-if="data.item.status ==='Rejected'" class="" style="padding:8px" variant="danger" >-->
              <!--                <span class=""> {{data.item.status}} </span>-->
              <!--              </b-badge>-->
              <!--              <b-badge v-if="data.item.status ==='Cancelled'" class="" style="padding:8px" variant="info" >-->
              <!--                <span class=""> {{data.item.status}} </span>-->
              <!--              </b-badge>-->


            </template>
            <template #cell(actions)="data">
              <b-row class="d-flex-center text-left">
                <b-col class="d-flex " cols="12">
                  <b-button
                      id="popover-reactive-2"
                      ref="button2"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="btn-icon bg-white text-primary "
                      size="23"
                      v-b-tooltip.hover.top="'Accept'"
                      variant="outline-white"
                      @click="acceptRequest(data.item)"
                  >
                    <feather-icon icon="CheckSquareIcon" size="18"/>
                  </b-button>
                  <!-- Wrong Button -->

                  <b-button
                      id="popover-reactive-1"
                      ref="button"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="btn-icon bg-white text-danger ml-1"
                      v-b-tooltip.hover.top="'Decline'"
                      size="23"
                      variant="outline-white"
                  >
                    <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
                  </b-button>
                </b-col>
              </b-row>
            </template>

          </b-table>
        </b-overlay>
      </b-col>
      <b-col v-if="noDataTable === 0" class="text-center" cols="12">
        <span>No data for preview</span>
      </b-col>
      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span v-if="pagination.totalRows !==0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{
            pagination.totalRows
          }} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>

  </div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Filter from '@/mixins/FilterMixin'
import {
  BAlert,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BListGroupItem,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  VBModal,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import OutstandingInvoice from "@/views/invoice/outstanding-invoice";
import PaidInvoice from "@/views/invoice/paid-invoice";
import ConnectedLocations from "@/views/locations/connected-locations";
import ConnectionRequests from "@/views/locations/connection-requests";
import locations from "@/apis/modules/locations";


export default {
  name: "connection-locations",
  components: {
    ConnectionRequests,
    ConnectedLocations,
    PaidInvoice,
    OutstandingInvoice,
    BTabs,
    BCardText,
    BTab,
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,
    BButtonGroup,
    BOverlay, BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,


  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin, Filter],

  data() {
    return {
      filters: {
        search: ''
      },
      tableLoading: false,
      noDataTable: '',
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'name',
          label: 'NAME',
        },
        {
          key: 'address',
          label: 'ADDRESS',
        },
        {
          key: 'city',
          label: 'CITY',
        },
        {
          key: 'postal_code',
          label: 'Postal code',
        },
        {
          key: 'actions',
          label: 'Actions',
        }
      ]
    }
  },
  methods: {
    async acceptRequest(request) {
      try {
        this.tableLoading = true
        await locations.acceptRequest(request.id)
        this.$refs.table.refresh()
        this.showSuccessMessage('Invitation accepted successfully')
        this.tableLoading = false
      } catch (error) {
        this.tableLoading = false
        this.convertAndNotifyError(error)
      }
    },
    openInvitationCrateModel() {
      this.showCreateInvoice = true
    },
    filterQueryUpdate() {
      this.$refs.table.refresh()
    },
    async getConnectedLocation() {
      try {
        this.tableLoading = true
        // const Response = await settings.userList(this.sortBy)
        if (this.sortBy === '') {
          this.sortDesc = true
        }

        const Response = await locations.getLocationRequestsFromPractices(this.currentPage, 10, this.filterQuery)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.practice.name,
          address: x.practice.address,
          city: x.practice.city,
          postal_code: x.practice.postal_code,
        }))

        const paginationResponse = Response.data
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    search(value) {
      this.filters.search = value
    }

  }
}
</script>

<style scoped>

</style>
