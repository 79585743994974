<template>
  <div>
    <b-card>
      <b-col class="mb-2" cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon"/>
            </b-input-group-prepend>
            <b-form-input
                id="icons-search"
                v-model="search"
                placeholder="Search"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-tabs v-model="step" lazy>
        <b-tab title="Connected Locations">
          <connected-locations ref="accepted"/>
        </b-tab>
        <b-tab title="Connect request pending Locations">
          <connect-request-pending-location ref="pending"/>
        </b-tab>
        <b-tab title="Connection Requests">
          <connection-requests ref="requests"/>
        </b-tab>
      </b-tabs>

    </b-card>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BListGroupItem,
  BMedia,
  BModal,
  BOverlay,
  BRow,
  BTab,
  BTabs,
  VBModal,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import OutstandingInvoice from "@/views/invoice/outstanding-invoice";
import PaidInvoice from "@/views/invoice/paid-invoice";
import ConnectedLocations from "@/views/locations/connected-locations";
import ConnectionRequests from "@/views/locations/connection-requests";
import ConnectRequestPendingLocation from "@/views/locations/connect-request-pending-locations";

export default {
  name: "index",
  components: {
    ConnectRequestPendingLocation,
    ConnectionRequests,
    ConnectedLocations,
    PaidInvoice,
    OutstandingInvoice,
    BTabs,
    BCardText,
    BTab,
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,

    BOverlay,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      search: '',
      step: 0,
      searchProp: {}
    }
  },
  watch: {
    step() {
      setTimeout(() => {
        this.search = ''
      },200)
    },
    search(value) {
      this.searchProp = {}
      if (this.step === 0) {
        this.$refs.accepted.search(value)
      } else if (this.step === 1) {
        this.$refs.pending.search(value)
      } else {
        this.$refs.requests.search(value)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
/*print style*/
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.shift-card {
  //margin-bottom: 0px;
  //padding-bottom: 10px;
}


</style>
