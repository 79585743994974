<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >

          <b-table
              :current-page="currentPage"
              :per-page="pagination.perPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="getConnectedLocation"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              show-empty
              hover
              ref="table"

              class="mobile_table_css"
              responsive
          >

            <template #cell(duration)="data">
              <!--              <div v-if="data.item.is_day">-->
              <!--                <span v-if="data.item.duration > 1">{{data.item.duration}} days</span>-->
              <!--                <span v-else >{{data.item.duration}} day</span>-->
              <!--              </div>-->
              <!--              <div v-else>-->
              <!--                <span v-if="data.item.duration > 1" >{{data.item.duration}} hours</span>-->
              <!--                <span v-else >{{data.item.duration}} hour</span>-->

              <!--              </div>-->


            </template>

            <template #cell(status)="data">
              <b-badge v-if="data.item.status === 'Paid'"  class="width-100" style="padding:8px" variant="light-primary" >
                <span class=""> {{data.item.status}} </span>
              </b-badge>
              <b-badge v-else  style="padding:8px" class="width-100" variant="light-warning" >
                <span class=""> {{data.item.status}} </span>
              </b-badge>
              <!--              <b-badge v-if="data.item.status ==='Approved'" class="" style="padding:8px" variant="success" >-->
              <!--                <span class=""> {{data.item.status}} </span>-->
              <!--              </b-badge>-->
              <!--              <b-badge v-if="data.item.status ==='Rejected'" class="" style="padding:8px" variant="danger" >-->
              <!--                <span class=""> {{data.item.status}} </span>-->
              <!--              </b-badge>-->
              <!--              <b-badge v-if="data.item.status ==='Cancelled'" class="" style="padding:8px" variant="info" >-->
              <!--                <span class=""> {{data.item.status}} </span>-->
              <!--              </b-badge>-->


            </template>

          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12" v-if="noDataTable === 0" class="text-center">
        <span >No data for preview</span>
      </b-col>
      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>

  </div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Filter from '@/mixins/FilterMixin'
import {

  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BImg,
  BLink,
  BMedia, BOverlay,
  BRow,
  VBTooltip,
  BListGroupItem,
  BCardTitle,
  BFormGroup,
  BTabs,
  BCardText,
  BTab,

  VBToggle,
  BModal, VBModal, BAlert,

  BButtonGroup,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BAvatarGroup,
  BFormInput,
  BTable,

  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import OutstandingInvoice from "@/views/invoice/outstanding-invoice";
import PaidInvoice from "@/views/invoice/paid-invoice";
import ConnectedLocations from "@/views/locations/connected-locations";
import ConnectionRequests from "@/views/locations/connection-requests";
import locations from "@/apis/modules/locations";


export default {
  name: "connection-locations",
  components: {
    ConnectionRequests,
    ConnectedLocations,
    PaidInvoice,
    OutstandingInvoice,
    BTabs,
    BCardText,
    BTab,
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,
    BButtonGroup,
    BOverlay, BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin,Filter],

  data() {
    return {
      filters: {
        search : ''
      },
      tableLoading:false,
      noDataTable:'',
      uploadedFile: null,
      showFileUploadModal:false,
      modalLoading:false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'name',
          label:'NAME',
        },
        {
          key: 'address',
          label: 'ADDRESS',
        },
        {
          key: 'city',
          label: 'CITY',
        },

        {
          key: 'postal_code',
          label: 'Postal code',
        }
      ],
    }
  },
  methods:{
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
    openInvitationCrateModel() {
      this.showCreateInvoice = true
    },

      async getConnectedLocation () {
        try {
          this.tableLoading = true
          // const Response = await settings.userList(this.sortBy)
          if (this.sortBy===''){
            this.sortDesc = true
          }

          const Response = await locations.getMyLocations(this.currentPage, 10,  this.filterQuery)
          const dataArray = Response.data.data.map((x) => ({
            id: x.id,
            name: x.practice.name,
            address: x.practice.address,
            city: x.practice.city,
            postal_code: x.practice.postal_code,
          }))

          const paginationResponse = Response.data
          this.currentPage = paginationResponse.current_page
          this.pagination.totalRows = paginationResponse.total
          this.pagination.perPage = paginationResponse.per_page
          this.pagination.from = paginationResponse.from
          this.pagination.to = paginationResponse.to
          this.tableLoading = false
          return dataArray

        } catch (error) {
          this.convertAndNotifyError(error)
          this.tableLoading = false
        }
      },
    search (value) {
      this.filters.search = value
    }
    }
}
</script>

<style scoped>

</style>
