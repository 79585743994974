
<template>
  <b-card>
    <b-row class="mb-2">
      <b-col  cols="3">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
                id="icons-search"
                placeholder="Statement Id"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col  cols="3">
        <b-form-group>
          <flat-pickr
              v-model="selectedDate"
              :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
              class="form-control"
              placeholder="Date"
          />
        </b-form-group>
      </b-col>

      <!--      <b-col cols="9">-->
      <!--        <b-button @click="requestLeave()" class="float-right" size="sm"-->
      <!--                  variant="primary">Request Leave</b-button>-->
      <!--      </b-col>-->
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >

          <b-table
              :current-page="currentPage"
              :per-page="pagination.perPage"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              :items="items"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"

              hover
              ref="table"

              class="mobile_table_css"
              responsive
          >
            <template #cell(duration)="data">
<!--              <div v-if="data.item.is_day">-->
<!--                <span v-if="data.item.duration > 1">{{data.item.duration}} days</span>-->
<!--                <span v-else >{{data.item.duration}} day</span>-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                <span v-if="data.item.duration > 1" >{{data.item.duration}} hours</span>-->
<!--                <span v-else >{{data.item.duration}} hour</span>-->

<!--              </div>-->


            </template>

            <template #cell(status)="data">
              <b-badge  class="" style="padding:8px" variant="danger" >
                <span class=""> {{data.item.status}} </span>
              </b-badge>
<!--              <b-badge v-if="data.item.status ==='Approved'" class="" style="padding:8px" variant="success" >-->
<!--                <span class=""> {{data.item.status}} </span>-->
<!--              </b-badge>-->
<!--              <b-badge v-if="data.item.status ==='Rejected'" class="" style="padding:8px" variant="danger" >-->
<!--                <span class=""> {{data.item.status}} </span>-->
<!--              </b-badge>-->
<!--              <b-badge v-if="data.item.status ==='Cancelled'" class="" style="padding:8px" variant="info" >-->
<!--                <span class=""> {{data.item.status}} </span>-->
<!--              </b-badge>-->


            </template>
            <template #cell(action)="data">
              <b-button
                  size=""
                  variant="outline-white"
                  class="btn-icon bg-white text-primary  "
                  v-b-tooltip.hover.top="'View'"
                  @click="$router.push('/invoice/view')"
              >
                <feather-icon icon="EyeIcon" size="18" />
              </b-button>



<!--              <b-button-->
<!--                  size="23"-->
<!--                  variant="outline-white"-->
<!--                  class="btn-icon bg-white text-danger m-1"-->
<!--                  v-b-tooltip.hover.top="'Reject'"-->
<!--                  @click="rejectLeave(data.item.id)"-->
<!--              >-->
<!--                <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>-->
<!--              </b-button>-->


            </template>


          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12" v-if="noDataTable === 0" class="text-center">
        <span >No data for preview</span>
      </b-col>
      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>

  </b-card>



</template>

<script>

import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import vSelect from 'vue-select'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'


export default {
  name: "outstanding-invoice",
  components: {

    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay


  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],

  data() {
    return {

      selectedLeave:{},
      isEditLeaveSidebarActive:false,
      isRequestLeaveSidebarActive: false,
      tableLoading:false,
      noDataTable:'',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'statement_id',
          label:'Statement ID',
        },
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'amount',
          label: 'Amount',
        },


        {
          key: 'status',
          label:'status',
        },
        {
          key: 'Action',
          label:' action',
        },




        // {
        //   key: 'action',
        //   label: '',
        // },

      ],
      items: [

          {
              id:'1',
              statement_id:'#23335678',
              date:'15/03/2021',
              amount:'£1,700.00',
              status:'Unpaid'
        },
        {
          id:'2',
          statement_id:'#23888678',
          date:'15/03/2021',
          amount:'£1,700.00',
          status:'Unpaid'
        },
        {
          id:'3',
          statement_id:'#44345678',
          date:'15/03/2021',
          amount:'£1,700.00',
          status:'Unpaid'
        },
        {
          id:'4',
          statement_id:'#22325678',
          date:'15/03/2021',
          amount:'£1,700.00',
          status:'Unpaid'
        },
      ],

      jobRoleOption: [],
      systemRoleOption:[],
      user_name:'',
      user_email:'',
      user_id:'',
      modelShow: false,
      email: '',
      system_role_ids: [],
      job_role_id: '',
    }
  },
  watch: {
    currentPage () {

      this.getMyLeaves()
    }
    /* 'selected_clinic' () {
     if (this.selected_clinic)  this.filters['clinic_id'] = this.selected_clinic.value
     }*/
  },

  methods:{
    async dataLoad(){

      this.$refs.table.refresh()
    },



  },



  mounted() {

    // this.getUsers()

  },


}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

div#dropdown-1 .btn-outline-danger {
  border: none !important;
}
</style>

