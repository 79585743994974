import api from '../axios';

export default {
    //locations request
    getMyLocations: (page, paginate, filterQuery) => api.get(`api/locum/locations/connected?page=${page}&paginate=${paginate}&${filterQuery}`),
    getPendingMyLocations: (page, paginate, filterQuery) => api.get(`api/locum/locations/requests?page=${page}&paginate=${paginate}&${filterQuery}`),
    getLocations: () => api.get(`api/locum/locations`),
    getLocationRequestsFromPractices: (page, paginate, filterQuery) => api.get(`api/locum/locations/requests-from-practices?page=${page}&paginate=${paginate}&${filterQuery}`),
    acceptRequest: (id) => api.post(`api/locum/locations/requests-from-practices/${id}/accept`),
};

